import { render, staticRenderFns } from "./newvirtual.vue?vue&type=template&id=de71cd28&scoped=true&"
import script from "./newvirtual.vue?vue&type=script&lang=js&"
export * from "./newvirtual.vue?vue&type=script&lang=js&"
import style0 from "./newvirtual.vue?vue&type=style&index=0&id=de71cd28&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de71cd28",
  null
  
)

export default component.exports